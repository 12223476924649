.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .login-container h2 {
    margin-bottom: 20px;
    color: var(--paynes-gray);
  }
  
  .login-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 300px;
  }
  
  .login-container label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: var(--paynes-gray);
  }
  
  .login-container input {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid var(--light-blue);
    font-size: 16px;
    outline: none;
    transition: border 0.2s ease-in-out;
  }
  
  .login-container input:focus {
    border-color: var(--celeste);
  }
  
  .login-container button {
    padding: 10px;
    border-radius: 8px;
    border: none;
    background-color: var(--melon);
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .login-container button:hover {
    background-color: var(--eggshell);
  }
  
  .error {
    color: red;
    font-size: 14px;
    margin-top: -10px;
  }
  