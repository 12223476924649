:root {
    --melon: #b4b0b0;
    --eggshell: #faf3dd;
    --celeste: #b8f2e6;
    --light-blue: #aed9e0;
    --paynes-gray: #5e6472;
  }

  button {
    padding: 10px 20px;
    border-radius: 8px; 
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: var(--paynes-gray); 
    background-color: var(--celeste); /* Плавное изменение цвета при наведении */
  }
  
 
  button:hover {
    background-color: var(--light-blue);
  }

  .cntr_btn {
    display: flex;
    align-items: center;
  }

  .q_users {
    font-size: 14px;
    color: var(--paynes-gray);
  }
  
  .table-container {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    background-color: var(--eggshell);
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .users-table th {
    background-color: var(--light-blue);
    color: var(--paynes-gray);
    padding: 12px 15px;
    font-size: 16px;
    text-align: left;
    cursor: pointer;
  }
  
  .users-table th:hover {
    background-color: var(--celeste);
  }
  
  .users-table td {
    padding: 10px 15px;
    font-size: 14px;
    color: var(--paynes-gray);
    border-bottom: 1px solid var(--light-blue);
  }
  
  .users-table tr:nth-child(even) {
    background-color: var(--celeste);
  }
  
  .users-table tr:hover {
    background-color: var(--melon);
    color: #fff;
  }
  
  .filter-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px;
    background-color: var(--paynes-gray);
    border-radius: 5px;
  }
  
  .filter-container input,
  .filter-container select {
    padding: 8px;
    border: 1px solid var(--light-blue);
    border-radius: 4px;
    color: var(--paynes-gray);
  }
  
  .filter-container input::placeholder,
  .filter-container select option {
    color: var(--light-blue);
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
  }
  
  button {
    margin: 5px;
  }

  .filter_label { 
    display: flex;
    flex-direction: column;
    gap: 7px;  
  }
  
  