.message-container {
    display: flex;
    width: 100%;
    margin: 5px 0;
}

.message-container.self {
    justify-content: flex-end;
}

.message-container.other {
    justify-content: flex-start;
}

.message-bubble {
    max-width: 70%;
    padding: 8px 12px;
    border-radius: 10px;
    background-color: #e3e3e3;
}

.message-container.self .message-bubble {
    background-color: #daf8e3;
    text-align: right;
}

.svg_del {
    margin-top: 10px;
    cursor: pointer;
}
