.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 60%;
    max-height: 80vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2 {
    margin-top: 0;
}

.messages {
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
    margin-top: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.message {
    display: inline-block;
    max-width: 80%;
    margin: 5px 0;
    padding: 8px 12px;
    border-radius: 10px;
}

.message.self {
    background-color: #daf8e3;
    align-self: flex-end;
    text-align: right;
}

.message.other {
    background-color: #e3e3e3;
    align-self: flex-start;
    text-align: left;
}

.close-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.close-button:hover {
    background-color: #0056b3;
}
